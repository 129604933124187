export default {
    init() {
        // JavaScript to be fired on all pages

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        $('.news__slider').slick({
            slidesToShow: 3,
            infinite: true,
            arrows: true,
            fade: false,
            speed: 500,
            autoplay: false,
            swipeToSlide: true,
            dots: false,
            nextArrow: '<div class="news__slider__next"><svg xmlns="http://www.w3.org/2000/svg" width="23.901" height="45.788" viewBox="0 0 23.901 45.788"><g id="right-arrow" transform="translate(-117.325 344.988)"><path id="Path_254" data-name="Path 254" d="M119.038.413a1,1,0,0,0-1.408,1.407l21.194,21.2-21.2,21.194a1,1,0,1,0,1.383,1.432l.024-.024,21.9-21.9a1,1,0,0,0,0-1.408Z" transform="translate(0 -345.121)" fill="#022955"/></g></svg></div>',
            prevArrow: '<div class="news__slider__prev"><svg xmlns="http://www.w3.org/2000/svg" width="23.903" height="45.801" viewBox="0 0 23.903 45.801"><g id="right-arrow" transform="translate(141.228 45.8) rotate(180)"><path id="Path_255" data-name="Path 255" d="M118.444,45.789a1,1,0,0,1-.7-1.7l21.2-21.194L117.739,1.7A1,1,0,0,1,119.149.292l21.9,21.9a1,1,0,0,1,0,1.408l-21.9,21.9A1,1,0,0,1,118.444,45.789Z" transform="translate(-0.111 0)" fill="#022955"/></g></svg></div>',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
            },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    },
            },
            ],
        });

        $('.applications__slider').slick({
            slidesToShow: 3,
            infinite: true,
            arrows: true,
            fade: false,
            speed: 500,
            autoplay: true,
            swipeToSlide: true,
            variableWidth: true,
            dots: false,
            nextArrow: '<div class="applications__slider__prev"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"/></svg></div>',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2,
                    },
            },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                    },
            },
            ],
        });

        $('.team__slider').slick({
            slidesToShow: 1,
            infinite: true,
            arrows: false,
            fade: true,
            speed: 500,
            autoplay: true,
            swipeToSlide: true,
            dots: false,
        });

        $('.team-slider__content').slick({
            dots: false,
            arrows: true,
            swipeToSlide: true,
            prevArrow: $('.slider__top__prev'),
            nextArrow: $('.slider__top__next'),
            asNavFor: '.team-slider__nav',
        });

        $('.team-slider__nav').slick({
            dots: false,
            arrows: false,
            swipeToSlide: true,
            slidesToShow: 3,
            focusOnSelect: true,
            asNavFor: '.team-slider__content',
        });

        $(window).scroll(function () {
            let scroll = $(window).scrollTop();
            if (scroll > 5) {
                $('.print-btn__fixed').addClass('show');
            } else {
                $('.print-btn__fixed').removeClass('show');
            }
        });

        jQuery(document).ready(function ($) {
            $('.services__block__inner .list-style').matchHeight();
        });


    function filter()
    {
        $('.archive__list__row').infiniteScroll({
            // options
            path: '.navigation .next',
            append: '.load__more__append',
            history: false,
            hideNav: '.navigation',
            scrollThreshold: false,
            button: '.btn--loadmore',
        });
    }

    filter();

    $(document).on('sf:ajaxfinish', '.searchandfilter', function () {
        filter();
    });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
