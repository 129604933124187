export default {
    init() {
        // JavaScript to be fired on the home page
        /* eslint-disable */
        Dropzone.autoDiscover = false;
        $(document).ready(function () {

            jQuery(document).on('gform_post_render', function (event, form_id) {
                if (form_id === 4) {
                    document.querySelector('.dropzone-js').classList.add('full');
                    window.gfDropzone();
                }
            });

            document.querySelector('.dropzone-js').classList.add('full');
            const dropzone = document.querySelector('.dropzone').dropzone;
            dropzone.on('addedfile', function(file) {
                const _ = this;
                // do this in a timeout to ensure that we have the `file.accepted` property
                setTimeout(function () {
                    if (!file.accepted) {
                        _.removeFile(file);
                        if (_.files.length === 0) {
                            jQuery('#input_4_8').val('0').change();
                        }
                    }
                }, 200);

                jQuery('#input_4_8').val('1').change();

                formChange();
            });

            jQuery(document).on('gform_post_render', function(event, form_id, current_page){
                formChange();
            });

            jQuery('#gform_4 li.gfield_contains_required input').attr('required', true);

            function formChange() {
                jQuery('.banner').css({
                    'max-height' : 'initial',
                })

                jQuery('.form__upload .dropzone-js .gfield_label').css({
                    'position' : 'initial',
                    'top' : 'initial',
                    'font-size' : '14px',
                    'padding-left' : 'initial',
                })

                jQuery('.form__upload .dropzone-js').css({
                    'margin-top' : '0',
                })

                jQuery('.input_4_2').focus();

                jQuery('.gform_title').show();

                jQuery('.dropzone-js .gfield_description').hide();
            }

        });
        /* eslint-enable */


    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
