export const headerPage = {
    init() {

        $(document).ready(function() {
            $('.header-search').on('click',  function () {
                $('.search-field').focus();
            });

            $('.header__menu__btn .btn, .header-search').on('click',  function () {

                if($(window).width() < 768) {
                    window.scroll(0, 0);
                }

                $('body').toggleClass('header__menu__open');

                let $text = $.trim($('.header__menu__text').text());

                let $open = $(this).data('open');
                let $close = $(this).data('close');

                if ($text == $open) {
                    $('.header__menu__text').text($close);
                } else if ($text == $close) {
                    $('.header__menu__text').text($open);
                }

                let $headerInnerHeight = $('.header__menu__inner').outerHeight();
                let $headerHeight = $('.header').outerHeight();
                let $windowHeight = $(window).height();
                let $calcHeight = $windowHeight - $headerHeight;

                if($headerInnerHeight > $calcHeight) {
                    $('.header__menu__inner').css({
                        'top' : '0',
                        'transform':'none',
                    })
                }
            });

            if ($(window).width() < 768) {

                $('.header-menu__block__item > div').append('<span class="menu__mobile__touch"></span>');

                $('.menu__mobile__touch').on('click', function () {
                    $(this).parent().find('.header__menu__item').slideToggle(150);
                    $(this).parent().find('.header__menu__title').toggleClass('header__menu__title--open')
                    $(this).remove();
                })
            }

            if ($(window).width() > 767) {
                $(window).scroll(function () {
                    let scroll = $(window).scrollTop();
                    if (scroll > 120) {
                        $('body').addClass('header--fix');
                    } else {
                        $('body').removeClass('header--fix');
                    }
                });
            }

            $('.header__search__toggle').on('click', function () {
                $('.header__search').toggleClass('open')
                $('.header__search__input input').focus()
            });
        });

    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
